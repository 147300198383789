export const googleAccount = [process.env.VUE_APP_X_GA_ECARSUIT, process.env.VUE_APP_X_GA];

export const gtmIdRenaultSE = 'GTM-PZLHRDB';
export const gtmIdRenaultDK = 'GTM-K2HXSPC';
export const gtmIdDaciaSE = 'GTM-MXVK6XJ';
export const gtmIdDaciaDK = 'GTM-TWFQ2MV';

export const googleAccountRenault = 'UA-4728947-1';
export const googleAccountDacia = 'UA-4728947-5';
export const googleAccountDanmark = 'UA-4728947-2';
export const googleAccountDanmarkDacia = 'UA-39005820-1';
